@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;

.root {
  position: fixed;
  background-color: $surface-primary;
  border: 1px solid $border-primary;
  width: fit-content;
  max-width: 600px;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 20px auto;
  border-radius: 99px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: bottom 0.4s ease-in-out;
  padding: 8px;
}

.hidden {
  bottom: -100px;
}

.badge {
  background-color: $primary-01;
  cursor: pointer;
  padding: 16px !important;
  color: $text-accent;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 99px !important;
}

.actionsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  width: 2px;
  height: 32px;
  background-color: $border-primary;
  margin-right: 16px;
  margin-left: 24px;
}

.actionIcon {
  cursor: pointer;
  color: $brand-primary-120;
}
