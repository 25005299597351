@import 'styles/mixins';
.refereeDetails {
  .refereeCardinal {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 13px;
    color: $text-secondary;
  }

  .details {
    margin-left: 10px;
  }
}

.disableFormSelector {
  opacity: 0.5;
  pointer-events: none;
}
