@use 'styles/mixins' as *;

.root {
  @include flex-box-centered;

  display: inline-flex;
  width: 4.5rem;
  height: 4.5rem;
  min-width: 4.5rem;
  min-height: 4.5rem;
  border-radius: 50%;
  cursor: pointer;

  &.small {
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
  }

  &.no-rounded {
    border-radius: 0;
  }

  &.default {
    color: #8479e6;
    position: relative;
    background: $surface-gradient-primary;
    padding: 2px; /* This creates the border width */
    box-shadow: 0px -3px 4px 0px #c5aaef inset;

    &::after {
      content: '';
      position: absolute;
      inset: 3px; /* Changed from -2px to 2px to create inner area */
      background: $border-gradient-primary; /* Add background to create contrast with gradient */
      border-radius: 50%;
      z-index: 0; /* Ensure it stays behind the content */
    }

    /* Add positioning for the icon content */
    > * {
      position: relative;
      z-index: 1;
    }
  }

  &.neutral-light {
    color: $text-secondary;
    background-color: $white;

    &.with-border {
      border: 2px solid $text-secondary;
    }
  }

  &.neutral-dark {
    color: $white;
    background-color: $neutral-10;

    &.with-border {
      border: 2px solid $white;
    }
  }

  &.error {
    color: $surface-status-error;
    background-color: $surface-status-error-20;

    &.with-border {
      border: 2px solid $border-error;
    }
  }

  &.success {
    color: $surface-status-success;
    background-color: $surface-status-success-20;

    &.with-border {
      border: 2px solid $border-success;
    }
  }

  &.info {
    color: $surface-status-passive;
    background-color: $surface-status-passive-20;

    &.with-border {
      border: 2px solid $surface-status-passive;
    }
  }

  &.warning {
    color: $surface-status-warning;
    background-color: $surface-status-warning-20;

    &.with-border {
      border: 2px solid $surface-status-warning;
    }
  }

  &.no-background {
    background: none !important;
    box-shadow: none !important;
    &::after {
      display: none;
    }
    border: none !important;

    &.default {
      color: $surface-gradient-primary;
    }
    &.neutral-light {
      color: $base-grey-50;
    }
    &.neutral-dark {
      color: $white;
    }
    &.danger {
      color: $surface-status-error;
    }
    &.success {
      color: $surface-status-success;
    }
    &.info {
      color: $surface-status-passive;
    }
    &.warning {
      color: $surface-status-warning;
    }
  }
}
