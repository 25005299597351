@import 'styles/mixins';

/***** COLORS *****/
.background-secondary {
  background-color: $background-secondary !important;
}

.text-secondary {
  color: $base-grey-50;
}

.text-color-primary {
  color: $brand-primary-160 !important;
}

.position-relative {
  position: relative;
}

/***** PORTVIEW *****/
.u-min-width {
  &-small {
    min-width: 300px;
  }
  &-medium {
    min-width: 500px;
  }
  &-large {
    min-width: 1000px;
  }
}

.u-max-width {
  &-small {
    max-width: 300px;
  }
  &-medium {
    max-width: 500px;
  }
  &-large {
    max-width: 1000px;
  }
}
.u-width-auto {
  width: auto !important;
}
.u-width-100 {
  width: 100% !important;
}
.u-width-25 {
  width: 25% !important;
}
.u-width-40 {
  width: 40% !important;
}
.u-width-45 {
  width: 45% !important;
}
.u-width-50 {
  width: 50% !important;
}
.u-width-75 {
  width: 75% !important;
}
.u-height-100 {
  height: 100% !important;
}
.u-max-width-content {
  padding-left: 7%;
  padding-right: 7%;
}
.u-width {
  &-x-small {
    width: $x-small-size;
  }
  &-small {
    width: $small-size;
  }
}

.u-height-100-vh {
  min-height: 100vh;
}

@media screen and (min-width: 1280px) {
  .u-max-width-content {
    max-width: 1280px !important;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.u-padding {
  padding: 15px;
  &--large {
    padding: 30px;
  }
  &--small {
    padding: 7px;
  }
  &--xSmall {
    padding: 5px;
  }

  &-right {
    padding-right: 15px;
    &--large {
      padding-right: 30px;
    }
    &--small {
      padding-right: 7px;
    }
    &--xSmall {
      padding-right: 5px;
    }
  }

  &-left {
    padding-left: 15px;
    &--large {
      padding-left: 30px;
    }
    &--small {
      padding-left: 7px;
    }
    &--xSmall {
      padding-left: 5px;
    }
  }

  &-top {
    padding-top: 15px;
    &--large {
      padding-top: 30px;
    }
    &--small {
      padding-top: 7px;
    }
    &--xSmall {
      padding-top: 5px;
    }
  }

  &-bottom {
    padding-bottom: 15px;
    &--large {
      padding-bottom: 30px;
    }
    &--small {
      padding-bottom: 7px;
    }
    &--0 {
      padding-bottom: 0 !important;
    }
  }

  &-x {
    padding-left: 15px;
    padding-right: 15px;

    &--large {
      padding-left: 30px;
      padding-right: 30px;
    }

    &--small {
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  &-y {
    padding-top: 15px;
    padding-bottom: 15px;

    &--small {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    &--large {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &-0 {
    padding: 0 !important;
  }
}

/***** TEXT *****/
.u-capitalize {
  text-transform: capitalize;
}
.u-uppercase {
  text-transform: uppercase;
}
.u-semibold {
  font-weight: 500;
}
.u-bold {
  font-weight: 600 !important;
}
.u-semi-bold {
  font-weight: 500 !important;
}
.u-light {
  font-weight: lighter;
}
.u-text-centered {
  text-align: center;
}
.u-text-left {
  text-align: left;
}
.u-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.u-font-weight-200 {
  font-weight: 200;
}
.u-font-weight-300 {
  font-weight: 300;
}
.u-font-weight-400 {
  font-weight: 400;
}
.u-font-weight-600 {
  font-weight: 600;
}
.u-font-weight-800 {
  font-weight: 800;
}

.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}

.d-hide {
  display: none;
}

/***** FLEXBOX *****/
.gap {
  &-1 {
    gap: 1%;
  }
  &-small {
    gap: $margin-small;
  }
  &-default {
    gap: $margin-default;
  }
  &-large {
    gap: $margin-large;
  }
}

.d-inline-flex {
  display: inline-flex;
}
.d-flex,
.u-flex-box {
  display: flex;
}
.no-wrap {
  flex-wrap: nowrap;
}
.flex-wrap,
.u-flex-box.u-flex-wrap {
  flex-wrap: wrap;
}
.justify-content-center,
.u-flex-box.u-flex-justify-center {
  justify-content: center;
}
.justify-content-end,
.u-flex-box.u-flex-justify-end {
  justify-content: flex-end;
}
.justify-content-start,
.u-flex-box.u-flex-justify-start {
  justify-content: flex-start;
}
.justify-content-between,
.u-flex-box.u-flex-justify-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-evenly;
}
.u-justify-self-center {
  justify-self: center;
}
.u-justify-self-end {
  justify-self: end;
}
.align-items-stretch,
.u-flex-box.u-flex-align-stretch {
  align-items: stretch;
}
.align-items-start,
.u-flex-box.u-flex-align-start {
  align-items: flex-start;
}
.align-items-end,
.u-flex-box.u-flex-align-end {
  align-items: flex-end;
}
.align-items-center,
.u-flex-box.u-flex-align-center {
  align-items: center;
}
.flex-column,
.u-flex-box.u-flex-direction-vertically {
  flex-direction: column;
}
.direction-row {
  flex-direction: row;
}
.u-flex-align-self-center {
  align-self: center;
}
.u-flex-align-self-end {
  align-self: flex-end;
}
.u-flex-align-self-normal {
  align-self: normal;
}
.u-centered-flex-box {
  @include flex-box-centered;
}

.justify-items-left {
  justify-items: left;
}

.flex-grow-1 {
  flex-grow: 1;
}

/***** GRID *****/
.d-grid {
  display: grid;
}

.grid-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}
.grid-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}
.grid-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}

.column-gap {
  column-gap: 15px;
}
.column-gap--small {
  column-gap: 7px;
}

/***** MARGIN *****/

.u-margin {
  margin: 16px;

  &--small {
    margin: 8px !important;
  }

  &--large {
    margin: 32px !important;
  }

  &-top {
    margin-top: 16px !important;

    &--small {
      margin-top: 8px !important;
    }

    &--large {
      margin-top: 32px !important;
    }

    &--0 {
      margin-top: 0 !important;
    }
  }

  &-right {
    margin-right: 16px;

    &--small {
      margin-right: 8px;
    }

    &--large {
      margin-right: 32px;
    }
  }

  &-left {
    margin-left: 16px;

    &--small {
      margin-left: 8px;
    }

    &--large {
      margin-left: 32px;
    }
  }

  &-bottom {
    margin-bottom: 16px !important;

    &--tiny {
      margin-bottom: 4px !important;
    }

    &--small {
      margin-bottom: 8px !important;
    }

    &--large {
      margin-bottom: 32px !important;
    }

    &--0 {
      margin-bottom: 0 !important;
    }
  }

  &-sides {
    margin: 0 16px;
  }

  &-x {
    margin-left: 16px;
    margin-right: 16px;

    &--small {
      margin-left: 8px;
      margin-right: 8px;
    }

    &--large {
      margin-left: 32px;
      margin-right: 32px;
    }
  }

  &-y {
    margin: 16px 0 !important;

    &--small {
      margin-top: 8px !important;
      margin-bottom: 8px !important;
    }

    &--large {
      margin-top: 32px !important;
      margin-bottom: 32px !important;
    }
  }

  &-0 {
    margin: 0 !important;
  }
}

.u-no-margin-top {
  margin: 0;
}
.u-no-margin-bottom {
  margin-bottom: 0 !important;
}
.u-no-margin {
  margin: 0 !important;
}

/***** LINK *****/
.u-link {
  @include link;
}

/***** CURSOR *****/
.u-pointer {
  cursor: pointer;
}

/***** DIVIDER *****/
@mixin divider {
  width: 100%;
  background-color: $brand-primary-15;
  height: 1px;
}
.u-divider {
  @include divider;
  margin: 1em 0;
}
.u-divider--large {
  @include divider;
  margin: 2em 0;
}

///////// Card /////////
.card {
  @include card;
}

///////// Design /////////
.design-marker {
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 55%;
    width: 100%;
    height: 100%;
    background-image: url(../assets/images/markup.svg);
    background-repeat: no-repeat;
    z-index: -1;
  }
}

///////// Text /////////
.title-1 {
  @include text-h1;
  color: $base-grey;
  margin: 0;
}

.title-2 {
  @include text-h2;
  color: $base-grey;
  margin: 0;
}

.title-3 {
  @include text-h3;
  color: $base-grey;
  margin: 0;
}

.title-4 {
  @include text-h4;
  color: $base-grey;
  margin: 0;
}

.title-5 {
  @include text-h5;
  color: $base-grey;
  margin: 0;
}

.title-6 {
  @include text-h6;
  color: $base-grey-90;
  margin: 0;
}

.t-subtitle {
  @include text-body;
  color: $base-grey-50;
  margin: 0;
}

.t-body {
  @include text-body;
  color: $base-grey;
}

.t-small {
  @include text-small;
  color: $base-grey-90;
}

.t-tiny {
  @include text-tiny;
}

// Responsiveness
@media (max-width: 1000px) {
  .sm-hide {
    display: none !important;
  }
}
@media (max-width: 700px) {
  .xs-hide {
    display: none !important;
  }
}

///////// Design /////////

.u-pointer {
  cursor: pointer;
}

.u-no-border {
  border: none !important;
}

.u-divider-bottom-border {
  border-bottom: $grey-border;
}
.u-divider-top-border {
  border-top: $grey-border;
}

.u-border-bottom-radius {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.u-border {
  border: $grey-border;
}

.u-border-right {
  border-right: $grey-border;
}

.u-border-left {
  border-left: $grey-border;
}

.u-border-top {
  border-top: $grey-border;
}

.u-border-bottom {
  border-bottom: $grey-border;
}

.u-border-radius {
  border-radius: 10px;
  &--xs-small {
    border-radius: 4px;
  }
  &--x-small {
    border-radius: 8px;
  }
  &--medium {
    border-radius: 16px;
  }
  &--large {
    border-radius: 24px;
  }
}

.u-drop-shadow {
  /* dropshadow/xs */
  &--xs {
    box-shadow:
      0px 1px 1px 0px rgba(75, 62, 102, 0.06),
      0px 2px 3px 0px rgba(75, 62, 102, 0.04);
  }
  /* dropshadow/sm */
  &--small {
    box-shadow: 0px 3px 3px 0px rgba(75, 62, 102, 0.08);
  }
  /* dropshadow/md */
  &--medium {
    box-shadow: 0px 8px 12px 0px rgba(75, 62, 102, 0.08);
  }
  /* dropshadow/lg */
  &--large {
    box-shadow: 0px 16px 25px 1px rgba(75, 62, 102, 0.08);
  }
  /* dropshadow/xl */
  &--focus {
    box-shadow: 0px 0px 4px 2px var(--primary-018047-f-320, rgba(128, 71, 243, 0.2));
  }
}

/***** Surfaces *****/

.surface-light-white {
  background-color: $surface-dessert-sand !important;
}

.surface-secondary {
  background-color: $surface-secondary;
}

.primary-text {
  color: $text-primary;
}

.secondary-text {
  color: $text-secondary;
}

/***** Position *****/

.u-absolute {
  position: absolute;
}

.u-relative {
  position: relative;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-static {
  position: static;
}

.u-top-0 {
  top: 0;
}

.u-top-10 {
  top: 10px;
}

.u-right-0 {
  right: 0;
}

.u-right-10 {
  right: 10px;
}

.u-bottom-0 {
  bottom: 0;
}

.u-left-0 {
  left: 0;
}

/***** Z-INDEX *****/

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-10 {
  z-index: 10;
}

.u-z-index-100 {
  z-index: 100;
}
